import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | Откройте для себя Horizon Realty
			</title>
			<meta name={"description"} content={"Войдите в сердце Horizon Realty, где каждое помещение рассказывает историю, которая ждет своего часа."} />
			<meta property={"og:title"} content={"О нас | Откройте для себя Horizon Realty"} />
			<meta property={"og:description"} content={"Войдите в сердце Horizon Realty, где каждое помещение рассказывает историю, которая ждет своего часа."} />
			<meta property={"og:image"} content={"https://brewaup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://brewaup.com/img/2389056-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brewaup.com/img/2389056-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brewaup.com/img/2389056-200.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://brewaup.com/img/2.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					О нас
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Откройте для себя Horizon Realty
				</Text>
				<Text font="--base">
					Отправляйтесь в путешествие по Horizon Realty, где каждый уголок таит в себе обещание возможностей и свидетельствует о нашей непоколебимой приверженности к совершенству. Основанная на принципах добросовестности и инноваций, наша компания превращает дома в жилье, а клиентов - в дорогих членов нашей большой семьи.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://brewaup.com/img/3.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://brewaup.com/img/4.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					В Horizon Realty мы гордимся своей приверженностью к индивидуальному обслуживанию и вниманию к деталям. С того момента, как вы входите в наши двери, вас встречают с теплом и энтузиазмом, и мы вместе отправляемся на поиски вашего идеального жилья.{" "}
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://brewaup.com/img/5.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Внимательно следя за деталями и стремясь к совершенству, мы создаем портфель недвижимости на любой вкус и предпочтения, гарантируя, что ваше путешествие с нами будет необычным.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-3">
			<Text
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Почему стоит выбрать Horizon Realty?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				padding="0px 50px 0px 50px"
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
						color="#65696d"
						text-align="center"
						border-color="#7a7c7f"
					>
						Индивидуальное обслуживание с учетом ваших уникальных потребностей.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
						color="#65696d"
						text-align="center"
						border-color="#7a7c7f"
					>
						Преданная команда профессионалов, стремящаяся превзойти ваши ожидания.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
						color="#65696d"
						text-align="center"
						border-color="#7a7c7f"
					>
						Обширный портфель недвижимости, охватывающий различные стили и местоположения.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				padding="0px 50px 0px 50px"
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
						color="#65696d"
						text-align="center"
						border-color="#7a7c7f"
					>
						Прозрачное и честное общение на протяжении всего процесса.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
						color="#65696d"
						text-align="center"
						border-color="#7a7c7f"
					>
						Наследие совершенства, построенное на честности, доверии и надежности.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" md-padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-9">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				border-radius="40px"
				lg-flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-display="flex"
					lg-flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 40px 0px"
				>
					<Text
						color="--dark"
						text-align="left"
						font="normal 700 42px/1.2 --fontFamily-sans"
						sm-font="normal 700 36px/1.2 --fontFamily-sans"
						margin="0 0px 30px 0px"
						border-color="--color-darkL1"
					>
						Каждая история начинается с Horizon Realty
					</Text>
					<Text
						margin="0px 0 50px 0"
						text-align="left"
						font="--lead"
						color="#5c6267"
						lg-margin="0px 0 30px 0"
						sm-margin="0px 0 36px 0"
						lg-text-align="center"
						lg-max-width="640px"
					>
						Создайте свою историю вместе с нами, и пусть Horizon Realty станет холстом, на котором ваши мечты обретут форму. Станьте частью нашего яркого сообщества, где каждый дом - это шедевр, который ждет своего часа.
					</Text>
				</Box>
				<Image
					src="https://brewaup.com/img/6.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					lg-height="auto"
					padding="0px 0px 0px 50px"
					lg-padding="0px 0px 0px 0"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});